body,
div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
nav,
ul,
li,
span,
a {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: "Open Sans Condensed", sans-serif;
    color: #3f2803;
}
h1 {
    font-size: 60px;
    line-height: 40px;
    padding-bottom: 30px;
}
h2 {
    font-size: 40px;
    line-height: 30px;
    padding-bottom: 40px;
}
h3 {
    font-size: 30px;
    line-height: 25px;
    padding-bottom: 40px;
}
h4 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    padding-bottom: 30px;
}
h5 {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.5;
}
h6 {
    font-size: 14px;
    line-height: 14px;
    line-height: 20px;
    letter-spacing: 1.2;
    color: #fff;
}
header {
    width: 100%;
    float: left;
}
header .top {
    width: 100%;
    background: #86da27;
    height: 20px;
    position: relative;
}
header .top a {
    float: right;
    padding-right: 20px;
    padding-top: 1px;
    font-size: 12px;
}
header .top a:hover,
header .top a:focus,
header .top a:active {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

header .middle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
header .middle .user,
header .middle .img,
header .middle .cart {
    width: 33.3%;
    float: left;
}
header .middle .user .login,
header .middle .cart {
    margin-top: 47px;
}
header .middle .cart .content,
header .middle .cart .user_name {
    margin-left: 40px;
    float: right;
}

header .middle a {
    text-transform: uppercase;
    padding: 0 15px;
    color: #757575;
    letter-spacing: 0.1em;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.287em;
    -webkit-transition: color 0.5s;
    -moz-transition: color 0.5s;
    -o-transition: color 0.5s;
    transition: color 0.5s;
    cursor: pointer;
    text-decoration: none;
    font-family: "Open Sans";
}

header .middle .user_name a {
    cursor: context-menu;
}

header .middle a:hover,
header .middle a:focus {
    color: #86da27;
    -webkit-transition: color 0.5s;
    -moz-transition: color 0.5s;
    -o-transition: color 0.5s;
    transition: color 0.5s;
}

header .middle .img {
    position: relative;
    width: 210px;
    margin: 60px auto;
}
header .middle .img img {
    width: 100%;
}

header nav {
    border-top: 1px solid #ebedf0;
    border-bottom: 1px solid #ebedf0;
}
header nav .menu {
    width: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
}
header nav .menu li {
    display: inline;
    margin: 10px 0;
}
header nav .menu li:first-child {
    border-right: 2px solid #ebedf0;
}
header nav .menu a {
    text-transform: uppercase;
    padding: 5px 15px;
    color: #3f2803;
    letter-spacing: 0.2em;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.287em;
    -webkit-transition: color 0.5s;
    -moz-transition: color 0.5s;
    -o-transition: color 0.5s;
    transition: color 0.5s;
    text-decoration: none;
}
header nav .menu li:hover {
    cursor: pointer;
}
header nav .menu li:hover a {
    color: #86da27;
    -webkit-transition: color 0.5s;
    -moz-transition: color 0.5s;
    -o-transition: color 0.5s;
    transition: color 0.5s;
}
@media (max-width: 1020px) {
    header .middle .user,
    header .middle .cart {
        width: 40%;
    }
    header .middle .img {
        width: 20%;
    }
}
@media (max-width: 900px) {
    header .middle {
        width: 95%;
    }
}

@media (max-width: 850px) {
    header .middle {
        width: 100%;
    }
}
@media (max-width: 730px) {
    header .middle .user .login img,
    header .middle .user .regist img,
    header .middle .cart img {
        width: 35px;
    }
    header .middle .user .login a,
    header .middle .user .regist a,
    header .middle .cart a,
    header .middle .user_name a {
        font-size: 13px;
        padding: 0;
    }
}

@media (max-width: 678px) {
    header nav .menu {
        width: 75%;
    }
    header nav .menu li {
        text-align: center;
        margin-left: 15px;
        padding-right: 10px;
    }
    header nav .menu a {
        padding: 0;
    }
}
@media (max-width: 650px) {
    header .middle .img {
        width: 20%;
    }
    header .middle .user,
    header .middle .cart {
        width: 40%;
    }
    header .middle .user .login,
    header .middle .cart {
        margin-top: 40px;
    }
}
@media (max-width: 600px) {
    header .middle .user .login img,
    header .middle .user .regist img,
    header .middle .cart img {
        width: 30px;
    }
    header .middle .user .login a,
    header .middle .user .regist a,
    header .middle .cart a,
    header .middle .user_name a {
        font-size: 12px;
        padding: 0;
    }
    header .middle .user .login,
    header .middle .cart {
        margin-top: 47px;
    }
}

@media (max-width: 550px) {
    header .middle .user,
    header .middle .cart,
    header .middle .img {
        width: 100%;
    }
    header .middle .cart .content,
    header .middle .cart .user_name {
        margin-left: 40px;
    }
    header .middle .img img {
        width: 180px;
        position: relative;
        left: 50%;
        margin-left: -90px;
    }
    header .middle .user .login img,
    header .middle .user .regist img,
    header .middle .cart img {
        width: 35px;
    }
    header .middle .user .login a,
    header .middle .user .regist a,
    header .middle .cart a {
        font-size: 12px;
        padding: 0 15px;
    }
    header .middle .user .login,
    header .middle .user .regist,
    header .middle .cart {
        width: 80%;
        margin: 0 auto;
    }
    header .middle .img {
        margin: 30px auto;
    }
    header .middle .user {
        margin-top: 30px;
    }
    header .middle .cart {
        margin-bottom: 30px;
    }
    /*header .middle .cart .content,*/
    /*header .middle .cart .user_name{*/
    /*float: left;*/
    /*}*/
    header .middle .cart a {
        /*float: right;*/
        padding-top: 20px;
    }
}
@media (max-width: 450px) {
    header .middle .user .login a,
    header .middle .user .regist a,
    header .middle .cart a {
        font-size: 11px;
        padding: 0;
        padding-top: 20px;
    }
    header .middle .img img {
        width: 120px;
        margin-left: -60px;
    }
    header .middle .img {
        margin: 15px auto;
    }
    header .middle .user .login,
    header .middle .user .regist,
    header .middle .cart {
        width: 70%;
        margin: 0 auto;
    }
    header .middle .cart .content,
    header .middle .cart .user_name {
        margin-left: 0;
    }
    header .middle .cart {
        margin-bottom: 30px;
    }
}
@media (max-width: 420px) {
    header .top a {
        padding-right: 17px;
        padding-top: 2px;
        font-size: 9px;
    }
}
