footer {
    width: 100%;
    float: left;
    border-top: 2px solid #86da27;
}
footer .box {
    width: 85%;
    margin: 0 auto;
}
footer .box .address {
    width: 100%;
    margin: 0 auto;
    padding-top: 40px;
}
footer .box .address .first {
    width: 50%;
    float: left;
}
footer .box .address .second {
    width: 30%;
    float: left;
}
footer .box .mail {
    width: 20%;
    float: left;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
}
footer .box .address .first img,
footer .box .address .second img {
    float: left;
}
footer .box .address .first .text,
footer .box .address .second .text {
    float: left;
    padding-top: 10px;
}
footer .box .address .first h5,
footer .box .address .second h5 {
    padding-left: 20px;
    padding-top: 5px;
    color: #757575;
    font-weight: normal;
}
footer .box .mail img {
    float: left;
}
footer .box .mail .content a {
    font-size: 16px;
    color: #757575;
    font-weight: normal;
    float: left;
    padding-top: 23px;
    padding-left: 20px;
    text-decoration: none;
}
footer .green-line {
    width: 100%;
    float: left;
    background: #86da27;
    margin-top: 40px;
}
footer .green-line h6 {
    text-align: center;
}

footer {
    .footer__certificate {
        width: 90%;
        height: 70px;
        position: relative;
        display: flex;
        justify-content: center;
        text-align: center;
        margin: 30px auto 40px;

        img:first-child {
            display: none;
        }
        @media (min-width: 550px) {
            height: 130px;
        }

        @media (min-width: 800px) {
            width: 100%;

            img:first-child {
                display: block;
            }

            img:last-child {
                display: none;
            }
        }

        img {
            // position: absolute;
            // object-fit: cover;
            // left: 0;
            // top: 0;
            // bottom: 0;
            // right: 0;
            box-shadow: 0px 1px 10px rgba(169, 216, 89, 0.6);
        }
    }

    // a.lightbox img {
    //     height: 100%;
    //     border: 3px solid white;
    //     box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    //     margin: 20px;
    // }

    // /* Styles the lightbox, removes it from sight and adds the fade-in transition */

    // .lightbox-target {
    //     position: fixed;
    //     top: -100%;
    //     width: 100%;
    //     background: rgba(0, 0, 0, 0.7);
    //     width: 100%;
    //     opacity: 0;
    //     -webkit-transition: opacity 0.5s ease-in-out;
    //     -moz-transition: opacity 0.5s ease-in-out;
    //     -o-transition: opacity 0.5s ease-in-out;
    //     transition: opacity 0.5s ease-in-out;
    //     overflow: hidden;
    //     z-index: 99999999;
    // }

    // /* Styles the lightbox image, centers it vertically and horizontally, adds the zoom-in transition and makes it responsive using a combination of margin and absolute positioning */

    // .lightbox-target img {
    //     margin: auto;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     max-height: 0%;
    //     max-width: 0%;
    //     border: 3px solid white;
    //     box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    //     box-sizing: border-box;
    //     -webkit-transition: 0.5s ease-in-out;
    //     -moz-transition: 0.5s ease-in-out;
    //     -o-transition: 0.5s ease-in-out;
    //     transition: 0.5s ease-in-out;
    // }

    // /* Styles the close link, adds the slide down transition */

    // a.lightbox-close {
    //     display: block;
    //     width: 50px;
    //     height: 50px;
    //     box-sizing: border-box;
    //     background: white;
    //     color: black;
    //     text-decoration: none;
    //     position: absolute;
    //     top: -80px;
    //     right: 0;
    //     -webkit-transition: 0.5s ease-in-out;
    //     -moz-transition: 0.5s ease-in-out;
    //     -o-transition: 0.5s ease-in-out;
    //     transition: 0.5s ease-in-out;
    // }

    // /* Provides part of the "X" to eliminate an image from the close link */

    // a.lightbox-close:before {
    //     content: "";
    //     display: block;
    //     height: 30px;
    //     width: 1px;
    //     background: black;
    //     position: absolute;
    //     left: 26px;
    //     top: 10px;
    //     -webkit-transform: rotate(45deg);
    //     -moz-transform: rotate(45deg);
    //     -o-transform: rotate(45deg);
    //     transform: rotate(45deg);
    // }

    // /* Provides part of the "X" to eliminate an image from the close link */

    // a.lightbox-close:after {
    //     content: "";
    //     display: block;
    //     height: 30px;
    //     width: 1px;
    //     background: black;
    //     position: absolute;
    //     left: 26px;
    //     top: 10px;
    //     -webkit-transform: rotate(-45deg);
    //     -moz-transform: rotate(-45deg);
    //     -o-transform: rotate(-45deg);
    //     transform: rotate(-45deg);
    // }

    // /* Uses the :target pseudo-class to perform the animations upon clicking the .lightbox-target anchor */

    // .lightbox-target:target {
    //     opacity: 1;
    //     top: 0;
    //     bottom: 0;
    // }

    // .lightbox-target:target img {
    //     max-height: 100%;
    //     max-width: 100%;
    // }

    // .lightbox-target:target a.lightbox-close {
    //     top: 0px;
    // }
}

@media (max-width: 1250px) {
    footer .box {
        width: 95%;
    }
}
@media (max-width: 1110px) {
    footer .box .address .first,
    footer .box .address .second {
        width: 50%;
        text-align: center;
    }
    footer .box .address .first img,
    footer .box .address .second img,
    footer .box .mail img {
        float: none;
    }
    footer .box .address .first .text,
    footer .box .address .second .text {
        width: 100%;
        margin: 0 auto;
    }
    footer .box .address .first h5,
    footer .box .address .second h5 {
        padding-left: 0;
        font-size: 15px;
    }
    footer .box .mail {
        width: 100%;
    }
    footer .box .mail .content {
        text-align: center;
    }

    footer .box .mail .content a {
        padding-top: 0;
        padding-left: 0;
        text-decoration: none;
        width: 100%;
    }
}
@media (max-width: 768px) {
    footer .box .address .first,
    footer .box .address .second,
    footer .box .mail {
        width: 100%;
        text-align: center;
    }
    footer .box .address .first .text,
    footer .box .address .second .text,
    footer .box .mail img {
        float: none;
    }
    footer .box .address .first h5,
    footer .box .address .second h5 {
        margin-top: 12px;
    }
    footer .box .address .second,
    footer .box .mail {
        margin-top: 30px;
    }
}
